import { Page, SortDirection } from "./Page";
import { API, RequiredQueryOptions } from "./network/API";

export type ArticleId = string & { isArtId: true };
export type UTCDate = string & { isUTCDate: true };

export enum CandyType {
  SWEET = "SWEET",
  CHEWING_GUM = "CHEWING_GUM",
  SOUR = "SOUR",
  SALTY = "SALTY",
  FOAM = "FOAM",
  LICORICE = "LICORICE",
  CHOCOLATE = "CHOCOLATE",
  CHIPS = "CHIPS",
  CANDY_PIECE = "CANDY_PIECE",
  DRINK = "DRINK",
  PACKAGING = "PACKAGING",
}

export interface Nutrients {
  kj: string; // Energi (kJ / kcal)
  kcal: string;
  fat?: string; //Fett
  satFat?: string; // - varav mättat fett
  carbs?: string; // Kolhydrater
  sugar?: string; // - varav sockerarter
  starch?: string; // - varav stärkelse
  polyoler?: string; // - varav polyoler
  protein?: string; // Protein
  salt?: string; // Salt
  fiber?: string; // Fiber
  correspondingSalt?: string; // Motsvarande salt
  natrium?: string; // Natrium
}

export interface CandyArticle {
  articleId: ArticleId;
  ean: string;
  created: UTCDate;
  updated: UTCDate;
  refreshed?: UTCDate;
  disabled?: UTCDate;
  displayName: string;
  productName: string;
  candyType?: CandyType;
  packageSize?: number;
  basePrice?: number;
  ourPrice?: number;
  ingredients?: string;
  ingredientsOriginal: string;
  palmOilFree: boolean;
  gelatinFree: boolean;
  vegan: boolean;
  nutritional: Nutrients;
}

export interface CandyRow {
  articleId: ArticleId;
  ean: string;
  refreshed?: UTCDate;
  disabled: boolean;
  pendingOrder: boolean;
  displayName: string;
  translationName: string;
  candyType?: CandyType;
  packageSize?: number;
  basePrice?: number;
  ourPrice?: number;
  kgPrice?: number;
  created: UTCDate;
}

export interface CandySearch {
  articleId: ArticleId;
  name: string;
  translationName: string;
  ean?: string;
  disabled: boolean;
}

export interface PriceHistoryEntry {
  id: number;
  created: UTCDate;
  basePrice: number;
  ourPrice: number;
  currency: string;
  provider: string;
}

export type ArticleFilter = Partial<{
  types: CandyType[];
}>;

const QUERY_KEY = "articles";

export const candyAPI = {
  QUERY_KEY: QUERY_KEY,

  fetchArticle: (articleId: ArticleId): RequiredQueryOptions<CandyArticle> => ({
    queryKey: [QUERY_KEY, articleId],
    queryFn: () => API.get<CandyArticle>(`/candy/${articleId}`),
  }),

  loadArticle: (articleId: ArticleId) => {
    return API.get<CandyArticle>(`/candy/${articleId}`);
  },

  loadArticles: (articleIds: ArticleId[]) => {
    // request param array
    return API.get<CandyArticle[]>(`/candy?ids=${articleIds.join(",")}`);
  },

  loadArticleByEan: (ean: string) => {
    return API.get<CandyArticle>(`/candy?ean=${ean}`);
  },

  fetchArticles: (
    page: number,
    size: number,
    direction: SortDirection,
    field: string,
    filters?: ArticleFilter
  ): RequiredQueryOptions<Page<CandyRow>> => ({
    queryKey: [QUERY_KEY, page, size, direction, field, filters],
    queryFn: () => {
      const filterParams = Object.entries(filters ?? {})
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}=${value.join(",")}`;
          } else {
            return `${key}=${value}`;
          }
        });

      return API.get<Page<CandyRow>>(
        `/candy?page=${page}&size=${size}&direction=${direction}&field=${field}&${filterParams.join(
          "&"
        )}`
      );
    },
  }),

  saveArticle: (article: CandyArticle) =>
    API.post(`/candy/${article.articleId}`, article),

  // searchArticle: (query: string): RequiredQueryOptions<CandySearch[]> => ({
  //   queryKey: [QUERY_KEY, "search", { query }],
  //   queryFn: () => API.get<CandySearch[]>(`/search?text=${query}`),
  // }),

  searchArticle: (query: string): RequiredQueryOptions<CandySearch[]> => ({
    queryKey: [QUERY_KEY, "search", { query }],
    queryFn: () => API.post<CandySearch[]>(`/search`, { text: query }),
  }),

  fetchArticlePriceHistory: (
    articleId: ArticleId,
    from: string,
    to: string
  ): RequiredQueryOptions<PriceHistoryEntry[]> => ({
    queryKey: [QUERY_KEY, articleId, "price-history", { from, to }],
    queryFn: () =>
      API.get<PriceHistoryEntry[]>(
        `/candy/${articleId}/price-history?from=${from}&to=${to}`
      ),
  }),
};
