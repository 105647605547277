import { Box, Grid2 as Grid, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CandyPage } from "../../components/layout/CandyPage";
import { authAPI } from "../../api/AuthAPI";
import { useQuery } from "@tanstack/react-query";

export const DASHBOARD_PAGE_ROUTE = "/";

export const DashboardPage = () => {
  return (
    <CandyPage title="Dashboard" skeleton={<PageSkeleton />}>
      <InnerDashboardPage />
    </CandyPage>
  );
};

const InnerDashboardPage = () => {
  const { t } = useTranslation();
  const { data } = useQuery(authAPI.whoami());
  return (
    <Box>
      <Typography>
        {t("Hello {{name}}", {
          name: data?.displayName,
        })}
      </Typography>
    </Box>
  );
};

const PageSkeleton = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Skeleton variant="rounded" height={40} />
        </Grid>
        {[...Array(10)].map((_, i) => (
          <Grid key={i} size={12}>
            <Skeleton variant="rounded" height={50} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
