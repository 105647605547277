import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { authAPI } from "../api/AuthAPI";
import { FunctionComponent, useState } from "react";
import {
  Button,
  ButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";

export const StoreInfo: FunctionComponent<ButtonProps> = ({ ...props }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: user } = useQuery(authAPI.whoami());
  const { data: stores } = useQuery(authAPI.getAvailableStores());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { mutate: handleStoreSelect } = useMutation({
    mutationFn: authAPI.selectStore,
    onSuccess: () => {
      queryClient.resetQueries();
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="store-menu-button"
        aria-controls={open ? "store-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.1s",
            }}
          />
        }
        startIcon="🛍️"
        variant="text"
        color={open ? "primary" : "inherit"}
        disableElevation
        {...props}
      >
        {stores?.find((store) => store.storeId === user?.selectedStore)
          ?.displayName ?? user?.selectedStore}
      </Button>
      <Menu
        id="store-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "store-menu-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem key="label" disabled dense>
          <ListItemText>
            <Typography variant="overline">{t("Select a store")}</Typography>
          </ListItemText>
        </MenuItem>
        {stores?.map((store) => {
          const selected = store.storeId === user?.selectedStore;
          return (
            <MenuItem
              key={store.storeId}
              onClick={() => handleStoreSelect(store.storeId)}
              selected={selected}
            >
              {selected && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
              <ListItemText inset={!selected}>{store.displayName}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
