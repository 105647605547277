import { Image, Text, View } from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import { FunctionComponent } from "react";
import { PrintQueueItem } from "../../api/PrintQueueAPI";
import rainbowHeader from "./header.png";
import { FormattedPrice } from "../FormattedPrice/FormattedPrice";

function generateEan(ean: string) {
  //EAN kan vara trasig
  if (ean === undefined || ean.length < 6) {
    return null;
  }
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, ean, {
    format: "EAN13",
  });
  const barcode = canvas.toDataURL();

  return (
    <Image
      src={barcode}
      style={{ width: "30mm", marginTop: "2mm", marginRight: "5mm" }}
    />
  );
}

export interface Props {
  item: PrintQueueItem;
}

export const PiecePricePrint: FunctionComponent<Props> = ({ item }) => {
  // const eanBarCode = generateEan(item.ean);

  return (
    <View
      wrap={false}
      break={false}
      style={{
        height: "42mm",
        width: "102mm",
        padding: "1mm",
        marginTop: "5mm",
        marginLeft: "5mm",
        marginRight: "5mm",
        justifyContent: "space-between",
        borderColor: "grey",
        borderWidth: "1px",
        borderStyle: "dotted",
      }}
    >
      <Image src={rainbowHeader} style={{ width: "100mm", height: "3mm" }} />
      <View
        style={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Text
          hyphenationCallback={(word) => [word]}
          style={{
            fontFamily: "PublicSans",
            marginLeft: "2mm",
            marginRight: "2mm",
            textAlign: "center",
          }}
        >
          {item.displayName.toLocaleUpperCase()}
        </Text>
      </View>
      <View
        style={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Text
          style={{
            fontSize: 28,
            fontFamily: "PublicSans",
          }}
        >
          <FormattedPrice value={item.price ? item.price : 0} currency="EUR" />
        </Text>
      </View>
    </View>
  );
};
