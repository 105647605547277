import { Image, Text, View } from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import { FunctionComponent, ReactElement } from "react";
import { CandyArticle, Nutrients } from "../../api/CandyAPI";
import rainbowHeader from "./header.png";
import vegan from "./vegan.png";
import milk from "./milk-red.png";
import soya from "./soya.png";
import treenut from "./treenut2.png";
import { PrintQueueItem } from "../../api/PrintQueueAPI";

// https://www.svgrepo.com/collection/food-allergen-line-icons/

export interface CandyLabel {
  candy: CandyArticle;
  translations: {
    productName: string;
    ingredients: string;
  };
}

function existsAndIsNotZero(value: string | undefined) {
  return value !== undefined && value !== "0.0";
}

function trimZero(value: string | undefined) {
  if (value === undefined) {
    return "";
  }
  return value.replace(".0", "");
}

function printNutrients(nutrition: Nutrients) {
  const energy = `Enerģētiskā vērtība ${trimZero(nutrition.kj)} kJ / ${trimZero(
    nutrition.kcal
  )} kcal`;
  const fat = nutrition.fat ? `Tauki ${trimZero(nutrition.fat)} g` : "";
  const saturatedFat = nutrition.satFat
    ? `tostarp piesātinātās taukskābes ${trimZero(nutrition.satFat)} g`
    : "";

  const carbs = nutrition.carbs
    ? `Ogļhidrāti ${trimZero(nutrition.carbs)} g`
    : "";
  const sugar = nutrition.sugar
    ? `tostarp cukuri ${trimZero(nutrition.sugar)} g`
    : "";
  const starch = existsAndIsNotZero(nutrition.starch)
    ? `tostarp ciete ${trimZero(nutrition.starch)}`
    : "";
  const polyoler = existsAndIsNotZero(nutrition.polyoler)
    ? `tostarp poliolers ${trimZero(nutrition.polyoler)}`
    : "";
  const protien = existsAndIsNotZero(nutrition.protein)
    ? `Olbaltumvielas ${trimZero(nutrition.protein)} g`
    : "";
  const salt = existsAndIsNotZero(nutrition.salt)
    ? `Sāls ${trimZero(nutrition.salt)} g`
    : "";
  const fiber = existsAndIsNotZero(nutrition.fiber)
    ? `Šķiedrvielas ${nutrition.fiber} g`
    : "";
  const correspondingSalt = existsAndIsNotZero(nutrition.correspondingSalt)
    ? `atbilstošā sāls ${trimZero(nutrition.correspondingSalt)} g`
    : "";
  const natrium = existsAndIsNotZero(nutrition.natrium)
    ? `nātrijs ${trimZero(nutrition.natrium)} g`
    : "";

  //TODO vi borde kunna beräkna fontsize baserat på antalet tecken

  const nutritionText = [
    energy,
    fat,
    saturatedFat,
    carbs,
    sugar,
    starch,
    polyoler,
    protien,
    salt,
    fiber,
    correspondingSalt,
    natrium,
  ]
    .filter((t) => t.length > 0)
    .join(", ");

  return (
    <Text style={{ fontSize: 9, fontFamily: "PublicSans" }}>
      <Text
        style={{ fontSize: 9, fontFamily: "PublicSans", fontWeight: "bold" }}
      >
        Uzturvērtība (100g).{" "}
      </Text>
      {nutritionText}
    </Text>
  );
}

function printIngredients(ingredients: string) {
  function printNormal(value: string): ReactElement {
    return (
      <Text key={value} style={{ fontSize: 9, fontFamily: "PublicSans" }}>
        {value}
      </Text>
    );
  }

  function printBold(value: string): ReactElement {
    return (
      <Text
        key={value}
        style={{ fontSize: 9, fontFamily: "PublicSans", fontWeight: "bold" }}
      >
        {value}
      </Text>
    );
  }

  const toPrint = [] as ReactElement[];

  //Index:  152 453
  // CandyRow.tsx:93 Next Bold:  152
  // CandyRow.tsx:108 Printing  152 152

  let currentIndex = 0;
  while (currentIndex < ingredients.length - 1) {
    const nextBold = ingredients.indexOf("<b>", currentIndex);
    if (nextBold === currentIndex) {
      const endBold = ingredients.indexOf("</b>", currentIndex);
      toPrint.push(printBold(ingredients.substring(currentIndex + 3, endBold)));
      currentIndex = endBold + 4;
      continue;
    }
    //no bold
    if (nextBold === -1) {
      toPrint.push(printNormal(ingredients.substring(currentIndex)));
      break; //Nothing more to print
    }
    // långt bort
    toPrint.push(printNormal(ingredients.substring(currentIndex, nextBold)));
    currentIndex = nextBold;
  }

  return (
    <Text style={{ fontSize: 9, fontFamily: "PublicSans" }}>
      <Text
        style={{ fontSize: 9, fontFamily: "PublicSans", fontWeight: "bold" }}
      >
        Sastāvdaļas:{" "}
      </Text>
      <>{toPrint}</>
    </Text>
  );
}

function generateEan(ean: string) {
  //EAN kan vara trasig
  if (ean === undefined || ean.length < 6) {
    return null;
  }
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, ean, {
    format: "EAN13",
  });
  const barcode = canvas.toDataURL();

  return (
    <Image
      src={barcode}
      style={{ width: "40mm", marginTop: "0mm", marginRight: "1mm" }}
    />
  );
}

export interface Props {
  item: PrintQueueItem;
  first: boolean;
}

export const CandyPrintRow: FunctionComponent<Props> = ({ item, first }) => {
  const eanBarCode = generateEan(item.ean);

  return (
    <View
      wrap={false}
      break={false}
      style={{
        // borderRadius: 6,
        height: "42mm",
        borderTopStyle: "dotted",
        borderTopColor: "grey",
        borderTopWidth: first ? "1px" : "",
        borderBottomStyle: "dotted",
        borderBottomColor: "grey",
        borderBottomWidth: "1px",
        marginTop: first ? "5mm" : "",
        paddingTop: "1px",
        paddingBottom: "1px",
        marginLeft: "5mm",
        marginRight: "5mm",
        justifyContent: "space-between",
      }}
    >
      <Image src={rainbowHeader} style={{ width: "100%" }} />
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text
          style={{
            marginLeft: "2mm",
            fontSize: item.displayName.length > 20 ? 24 : 28,
            fontFamily: "PublicSans",
          }}
        >
          {item.displayName.toLocaleUpperCase()}
        </Text>
        <View
          style={{
            alignItems: "flex-end",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {item.icons.vegan ? (
            <Image
              src={vegan}
              style={{ width: "20mm", marginTop: "0mm", marginRight: "1mm" }}
            />
          ) : null}
          {item.icons.containsNuts ? (
            <Image
              src={treenut}
              style={{ width: "20mm", marginTop: "0mm", marginRight: "1mm" }}
            />
          ) : null}
          {item.icons.containsMilk ? (
            <Image
              src={milk}
              style={{ width: "20mm", marginTop: "0mm", marginRight: "1mm" }}
            />
          ) : null}
          {item.icons.containsSoy ? (
            <Image
              src={soya}
              style={{ width: "20mm", marginTop: "0mm", marginRight: "1mm" }}
            />
          ) : null}
        </View>
        {eanBarCode}
      </View>
      <View style={{ paddingHorizontal: 8 }}>
        {printIngredients(item.ingredients ?? "")}
      </View>
      <View style={{ paddingHorizontal: 8 }}>
        {printNutrients(item.nutrients ?? "")}
      </View>
    </View>
  );
};
